import { Athena } from "@aws-sdk/client-athena";
import { useMemo, useState } from 'react';
import AthenaQuery from "@classmethod/athena-query";

const useAthenaQuery = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const awsCredentials = {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || "",
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || "",
  };

  const athenaQuery = useMemo(() => {
    const athena = new Athena({
      region: process.env.REACT_APP_AWS_REGION,
      credentials: awsCredentials,
    });

    return new AthenaQuery(athena, {
      db: process.env.REACT_APP_ATHENA_DB_NAME,
      workgroup: process.env.REACT_APP_ATHENA_WORKGROUP,
    });
  }, []);

  const buildMacnumQuery = (macnum: string, conditions: string) => {
    return `SELECT company_name, company_id, count(company_id) as allTickets,
    sum(case when impact = true then 1 else 0 end) as impactTickets
    FROM connectwise_tickets
    WHERE company_id='${macnum}' ${conditions} GROUP BY company_name, company_id`;
  };

  const buildGetCompanyQuery = (macnum: string) => {
    return `SELECT
        company_id,
        company_name
      FROM connectwise_tickets
      WHERE company_id='${macnum}'
      GROUP BY company_name, company_id`;
  };

  const getCompaniesQuery = (name: string) => {
    return `SELECT * FROM connectwise_companies WHERE LOWER(company_name) LIKE '%${name.toLowerCase()}%'`;
  };

  const executeQuery = async <T>(query: string): Promise<T[]>=> {
    setLoading(true);
    try {
      const results: T[] = [];
      for await (const item of athenaQuery.query(query)) {
        results.push(item as T);
      }
      setLoading(false);
      setError(false);
      return results;
    } catch (error) {
      setLoading(false);
      setError(true);
      throw error;
    }
  };

  return {
    loading,
    error,
    executeQuery,
    buildMacnumQuery,
    buildGetCompanyQuery,
    getCompaniesQuery,
  };
};

export default useAthenaQuery;
