import Logo from 'components/Logo';
import { StyledHeader } from './Header.styles';

const Header: React.FC = () => {
  return (
    <StyledHeader>
      <Logo />
    </StyledHeader>
  );
};

export default Header;
