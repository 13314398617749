import {
  StyledTitle,
  StyledLabel,
  StyledSearchInput,
  StyledContainer,
  StyledSubmitButton,
} from './Home.styles';
import PlaceholderResults from './components/PlaceholderResults';
import ResultsContainer from './components/ResultsContainer';
import NoResultsCard from './components/NoResultsCard';
import LoadingCard from './components/LoadingCard';
import { useEffect, useMemo, useState } from 'react';
import useAthenaQuery from 'shared/hooks/useAthenaQuery';
import { CompanyResponse, FilterType, MacnumResponse } from 'types';
import CompaniesList from './components/CompaniesList';
import { timeRangeFilter } from 'shared/utils/time';

const DEFAULT_FILTER = FilterType.SIX_MONTHS;

const Home: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [lastSearch, setLastSearch] = useState('');
  const [macnum, setMacnum] = useState('');
  const [progress, setProgress] = useState(0);
  const [activeFilter, setActiveFilter] = useState(DEFAULT_FILTER);
  const [data, setData] = useState<MacnumResponse[]>([]);
  const [companyData, setCompanyData] = useState<CompanyResponse[]>([]);
  const [companies, setCompanies] = useState<CompanyResponse[]>([]);
  const [resetSearch, setResetSearch] = useState(false);
  const {
    error,
    executeQuery,
    loading,
    buildMacnumQuery,
    buildGetCompanyQuery,
    getCompaniesQuery,
  } = useAthenaQuery();

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined;
    if (loading) {
      timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress < 0.9 ? prevProgress + 0.1 : 0.9));
      }, 200);
    } else {
      clearInterval(timer);
      setProgress(data ? 1 : 0);
    }

    return () => clearInterval(timer);
  }, [loading, data]);

  const timestampFilter = useMemo(() => {
    return timeRangeFilter(activeFilter);
  }, [activeFilter]);

  const companyExists = useMemo(() => {
    return companyData.length > 0;
  }, [companyData]);

  const query = useMemo(() => {
    return buildMacnumQuery(macnum, timestampFilter)
  }, [macnum, timestampFilter]);

  const queryText = useMemo(() => searchText.trim(), [searchText]);

  const handleSubmit = () => {
    setSubmitted(true);
    setProgress(0);
    searchTicketsByMacnum();
  };

  const handleSearch = () => {
    setSubmitted(true);
    setResetSearch(false);
    setCompanies([]);

    if (/^[0-9]+$/.test(queryText)) {
      searchCompanyByMacnum()
    } else {
      searchByCompanyName()
    }
  };

  const searchTicketsByMacnum = () => {
    executeQuery<MacnumResponse>(query).then((res) => {
      if (res.length > 0) {
        setData(res);
      } else {
        emptyData();
      }
      setCompanies([]);
    }).catch((error) => {
      console.error('Error executing query:', error);
    });
  };

  const emptyData = () => {
    setData([{
      company_name: companyData[0]?.company_name,
      company_id: companyData[0]?.company_id,
      allTickets: null,
      impactTickets: null
    }]);
  };

  const searchCompanyByMacnum = () => {
    executeQuery<MacnumResponse>(buildGetCompanyQuery(queryText)).then((res) => {
      if (res.length > 0) {
        setCompanyData(res);
        setMacnum(queryText);
      } else {
        setData([]);
        setCompanyData([]);
        setMacnum('');
      }
    }).catch((error) => {
      console.error('Error executing query:', error);
    });
  };

  const searchByCompanyName = () => {
    executeQuery<MacnumResponse>(getCompaniesQuery(queryText)).then((res) => {
      if (res.length > 0) {
        setCompanies(res);
      } else {
        setData([]);
        setCompanyData([]);
        setMacnum('');
      }
    }).catch((error) => {
      console.error('Error executing query:', error);
    });
  };

  const onSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setActiveFilter(DEFAULT_FILTER);
    setData([]);
    setResetSearch(true);
    setMacnum('');
    setLastSearch(queryText);
  }

  const selectMacnum = (company: CompanyResponse) => {
    setCompanyData([company]);
    setMacnum(company.company_id);
  }

  const handleFilterChange = (filter: FilterType) => {
    setActiveFilter(filter);
  }

  const noResults = () => {
    return submitted && !loading && !companyExists && companies.length < 1 && data.length < 1
  }

  useEffect(() => {
    if (submitted && !resetSearch) {
      handleSubmit();
    }
  }, [activeFilter]);

  useEffect(() => {
    if (resetSearch) {
      handleSearch();
    }
  }, [resetSearch]);

  useEffect(() => {
    if (macnum) {
      handleSubmit();
    }
  }, [macnum]);

  return (
    <div>
      <StyledTitle>
        Search Company
      </StyledTitle>
      <StyledLabel>
        Search by highest level macnum or company name
      </StyledLabel>
      <form onSubmit={onSearch}>
        <StyledContainer>
          <StyledSearchInput
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Ex: 02182980 or Penske Trucking"
            minLength={3}
            required
          />
          <StyledSubmitButton type="submit" disabled={loading}>
            Search
          </StyledSubmitButton>
        </StyledContainer>
      </form>
      <PlaceholderResults display={!submitted}/>
      <LoadingCard display={loading} progress={progress} />
      <NoResultsCard display={noResults()}/>
      { !loading && !error && companies.length > 0 && (
        <CompaniesList
          data={companies}
          term={lastSearch}
          onSelect={selectMacnum}
        />
      )}
      { !loading && !error && companyExists && (
        <ResultsContainer
          data={data}
          activeFilter={activeFilter}
          onFilterChange={handleFilterChange}
        />
      )}
    </div>
  );
};

export default Home;
