const colors = {
  primary: {
    100: '#61dafb',
  },
  text: {
    white: '#F8FAFC',
    primary: '#94A3B8',
    dark: '#191925',
    disabled: '#334155',
  },
  background: {
    light: '#fff',
    medium: '#222231',
    dark: '#191925',
    row: '#2B2B3F',
    rowHover: '#334155',
  },
  border: {
    light: '#94A3B8',
    medium: '#d6d8dB',
    dark: '#d8d8d9',
    hr: '#475569',
  },
  button: {
    primary: '#82F0FF',
    primaryHover: '#A7F4FF',
    disabled: '#94A3B8',
    secondary: '#E2E8F0',
  },
  segment: {
    light: '#82F0FF'
  }
};

const text = {
  size: {
    '2xs': '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '40px',
    '5xl': '52px',
  },
};

const spacing = {
  factorOf: (factor: number) => {
    const defaultSpacing = 4;
    return `${factor * defaultSpacing}px`;
  },
};

const breakpoints = {
  mobile: 375,
  tablet: 768,
};

const media = `@media (max-width: ${breakpoints.tablet}px)`;

const fonts = {
  body: 'Poppins, sans-serif',
  heading: 'Poppins, sans-serif',
  special: 'Helvetica, serif',
};

const customTheme = {
  colors,
  text,
  spacing,
  breakpoints,
  media,
  fonts,
};

export default customTheme;
