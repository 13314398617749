export type MessageState = {
  data: string;
};

export type MessageResponse = {
  message: string;
};

export type MacnumResponse = {
  allTickets: bigint | null;
  impactTickets: bigint | null;
  company_name: string;
  company_id: string;
};

export type CompanyResponse = {
  company_name: string;
  company_id: string;
};

export enum FilterType {
  ALL = 'all',
  ONE_MONTH = 'one_month',
  THREE_MONTHS = 'three_months',
  SIX_MONTHS = 'six_months',
}
