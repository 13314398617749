import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.colors.background.medium};
  gap: 16px;
`;

export const StyledCompanyRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.colors.background.row};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.background.rowHover};
  }
`;

export const StyledCompanyName = styled.div`
  font-weight: 500;
  font-size: ${({ theme }) => theme.text.size.md};
  color: ${({ theme }) => theme.colors.text.white};
  width: 55%;

  ${({ theme }) => theme.media} {
    font-size: ${({ theme }) => theme.text.size.sm};
    width: 60%;
  }
`;

export const StyledCompanyMacnum = styled.div`
  font-weight: 500;
  font-size: ${({ theme }) => theme.text.size.md};
  color: ${({ theme }) => theme.colors.text.white};
  min-width: 25%;

  ${({ theme }) => theme.media} {
    font-size: ${({ theme }) => theme.text.size.sm};
    min-width: 20%;
  }
`;

export const StyledAction = styled.div`
  display: flex;
  justify-content: right;
  margin-left: 20%;
  padding-right: 16px;

  ${({ theme }) => theme.media} {
    min-width: 5%;
    padding-right: 0px;
  }
`;

export const StyledChevronIcon = styled.img`
`;

export const StyledTitle = styled.p`
  font-weight: 700;
  font-size: ${({ theme }) => theme.text.size.xl};
  color: ${({ theme }) => theme.colors.text.white};
`;

export const StyledCompanyRowHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px 16px;
`;

export const StyledColumn = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.primary};
  min-width: 52%;

  ${({ theme }) => theme.media} {
    min-width: 50%;
  };
`;
