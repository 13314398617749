import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from 'screens/Home';
import Missing from './screens/Missing/Missing';
import DefaultLayout from './layouts/DefaultLayout/DefaultLayout';


const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<DefaultLayout />}>
          <Route index element={<Home />} />

          <Route path='*' element={<Missing />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
