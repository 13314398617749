import { FilterType } from "types";

export const timeRangeFilter = (filter: FilterType) => {
  const now = new Date();
  const oneMonthAgo = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
  const threeMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate());
  const sixMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());

  switch (filter) {
    case FilterType.ONE_MONTH:
      return `AND date_entered > TIMESTAMP '${oneMonthAgo.toISOString().split('T')[0]} 00:00:00'`;
    case FilterType.THREE_MONTHS:
      return `AND date_entered > TIMESTAMP '${threeMonthsAgo.toISOString().split('T')[0]} 00:00:00'`;
    case FilterType.SIX_MONTHS:
      return `AND date_entered > TIMESTAMP '${sixMonthsAgo.toISOString().split('T')[0]} 00:00:00'`;
    case FilterType.ALL:
      return '';
    default:
      return '';
  }
}
