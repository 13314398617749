import styled, { css } from "styled-components";

export const StyledFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  width: 443px;
  height: 40px;
  background: ${({ theme }) => theme.colors.background.dark};
  border-radius: 10px;
  margin-top: 10px;
  font-size: ${({ theme }) => theme.text.size.sm};

  ${({ theme }) => theme.media} {
    width: 100%;
  }
`;

export const StyledAction = styled.div<{ $active?: boolean }>`
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text.white};
  cursor: pointer;

  ${(props) =>
    props.$active &&
    css`
      background: ${({ theme }) => theme.colors.segment.light};
      border-radius: 8px;
      color: ${({ theme }) => theme.colors.text.dark};
    `}

  :hover {
    background: ${({ theme }) => theme.colors.segment.light};
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.text.dark};
  }
`;
