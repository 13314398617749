import { useState } from "react";
import { FilterType } from "types";
import { StyledCopyButton, StyledCopyResultsButtonContainer } from "../Home.styles"

type TextInfo = {
  companyName: string;
  macnum: string;
  totalIssues: string | number;
  resolvableIssues: string | number;
  filter: FilterType;
};

type Props = {
  textInfo: TextInfo
};

const INITIAL_DATE = new Date(2022, 8, 10);

const CopyResultsButton = ({ textInfo }: Props) => {
  const [copied, setCopied] = useState(false);

  const allTime = () => {
    const today = new Date();
    const diffTime = Math.abs(today.getTime() - INITIAL_DATE.getTime());
    const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
    return diffMonths;
  };

  const timePeriod = () => {
    switch (textInfo.filter) {
      case FilterType.ONE_MONTH:
        return 'month';
      case FilterType.THREE_MONTHS:
        return '3 months';
      case FilterType.SIX_MONTHS:
        return '6 months';
      case FilterType.ALL:
        return `${allTime()} months`;
      default:
        return '';
    }
  };

  const copyResultsToClipboard = () => {
    const text = `
Subject: Achieve Operational Excellence with Granite edgeboot\n

Did you know that 62% of network related incidents can be resolved with a simple reboot to the affected device?\nArmed with this intelligence, Granite sought out a means to automate this step of the troubleshooting process to better serve customers like ${textInfo.companyName}.\nGranite is proud to announce edgeboot: our innovative, cellular-based power distribution unit designed to revolutionize your operations. During the last ${timePeriod()}, you've experienced ${textInfo.totalIssues} incidents, ${textInfo.resolvableIssues} of which could have been prevented with edgeboot. Let's look at how edgeboot can bring you significant benefits:\n

  **Reduced Operational Costs**: edgeboot uses AI technology to manage power distribution autonomously, reducing manual intervention and subsequently your operational costs.\n
  **Boosted Network Resiliency**: edgeboot ensures a constant power supply, mitigating unexpected outages, and enhancing your network reliability.\n
  **Optimized Staff Productivity**: With routine power management tasks automated, your technical staff can devote their focus to more complex, strategic projects.\n
  **Remote Management**: Gain control over your network from anywhere, enabling swift, proactive actions, thus reducing downtime.\n

Embrace the future of operational efficiency and cost-effectiveness with Granite's edgeboot. For a demo or any queries, please don't hesitate to reach out.\nWe look forward to transforming ${textInfo.companyName}’s business with Granite edgeboot.\n
`
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <StyledCopyResultsButtonContainer>
      <StyledCopyButton onClick={copyResultsToClipboard}>
        Copy Results to Clipboard
      </StyledCopyButton>
      {copied && (
        <span className="tooltip-text">Copied to Clipboard!</span>
      )}
    </StyledCopyResultsButtonContainer>
  )
}

export default CopyResultsButton
