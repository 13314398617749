import { FC } from 'react';
import { StyledDefaultLayout, StyledContentLayout } from './DefaultLayout.styles';
import { Outlet } from 'react-router-dom';
import Header from 'components/Header/Header';

const DefaultLayout: FC = () => {
  return <StyledDefaultLayout>
    <Header />
    <StyledContentLayout>
      <Outlet />
    </StyledContentLayout>
  </StyledDefaultLayout>;
};

export default DefaultLayout;
