import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { MessageResponse } from 'types';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://nicolasgaviria.github.io/api/',
  }),
  endpoints: (builder) => ({
    getMessage: builder.query<MessageResponse, void>({
      query: () => ({
        url: 'message.json',
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetMessageQuery } = api;
