import { FilterType } from "types";
import { StyledAction, StyledFilterContainer } from "./FilterControl.styles";
import { StyledLabel } from "./ResultsContainer.styles";

type FilterControlProps = {
  activeFilter: FilterType;
  onFilterChange: (filter: FilterType) => void;
}

const FilterControl = ({activeFilter, onFilterChange}: FilterControlProps) => {

  const handleFilterChange = (filter: FilterType) => {
    onFilterChange(filter);
  }

  const isFilterActive = (filter: string) => {
    return filter === activeFilter;
  }

  return (
    <>
      <StyledLabel>Filter Results</StyledLabel>
      <StyledFilterContainer>
        <StyledAction
          $active={isFilterActive(FilterType.ONE_MONTH)}
          onClick={() => handleFilterChange(FilterType.ONE_MONTH)}
        >
          1 Month
        </StyledAction>
        <StyledAction
          $active={isFilterActive(FilterType.THREE_MONTHS)}
          onClick={() => handleFilterChange(FilterType.THREE_MONTHS)}
        >
          3 Months
        </StyledAction>
        <StyledAction
          $active={isFilterActive(FilterType.SIX_MONTHS)}
          onClick={() => handleFilterChange(FilterType.SIX_MONTHS)}
        >
          6 Months
        </StyledAction>
        <StyledAction
          $active={isFilterActive(FilterType.ALL)}
          onClick={() => handleFilterChange(FilterType.ALL)}
        >
          All
        </StyledAction>
      </StyledFilterContainer>
    </>
  )
}

export default FilterControl;
