import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type MessageState = {
  data: string;
};

const initialState: MessageState = { data: '' };

const { actions, reducer } = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage: (state, { payload }: PayloadAction<string>) => {
      state.data = payload;
    },
  },
});

export const { setMessage } = actions;
export default reducer;
