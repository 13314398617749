import { CompanyResponse } from "types";
import {
  StyledAction,
  StyledChevronIcon,
  StyledCompanyMacnum,
  StyledCompanyName,
  StyledCompanyRow,
} from "./CompaniesList.styles";
import chevronRight from 'assets/images/chevron-right.svg';

type CompanyCardProps = {
  company: CompanyResponse;
  onSelect: (company: CompanyResponse) => void;
};

const CompanyCard = ({ company, onSelect }: CompanyCardProps) => {
  return (
    <StyledCompanyRow onClick={() => onSelect(company)}>
      <StyledCompanyName>
        {company.company_name}
      </StyledCompanyName>
      <StyledCompanyMacnum>
        {company.company_id}
      </StyledCompanyMacnum>
      <StyledAction>
        <StyledChevronIcon src={chevronRight} alt="chevron-right" />
      </StyledAction>
    </StyledCompanyRow>
  )
}

export default CompanyCard;
