import styled from 'styled-components';

export const StyledProgressContainer = styled.div`
  width: 800px;
  height: 12px;
  background-color: #C8DDF6;
  border-radius: 50px;

  ${({ theme }) => theme.media} {
    width: 360px;
  }
`;

export const StyledBarProgress = styled.div<{ $progress: number }>`
  width: ${({ $progress }) => `${$progress * 800}px`};
  height: 100%;
  background-color: #3D79EF;
  border-radius: 50px;

  ${({ theme }) => theme.media} {
    width: ${({ $progress }) => `${$progress * 360}px`};
  }
`;
