import {
  StyledResultsContainer,
  StyledResultsTitle,
  StyledSearchImg,
} from '../Home.styles';
import error from 'assets/images/result_error.svg';

type Props = {
  display: boolean;
}

const NoResultsCard = ({display = true}: Props) => {
  if (!display) return null;

  return (
    <StyledResultsContainer>
      <StyledResultsTitle>
        Something went wrong!
      </StyledResultsTitle>
      <StyledResultsTitle>
        Please try again.
      </StyledResultsTitle>
      <StyledSearchImg src={error} alt="logo" />
    </StyledResultsContainer>
  )
}

export default NoResultsCard
