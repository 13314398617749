import CopyResultsButton from './CopyResultsButton';
import { FilterType, MacnumResponse } from 'types';
import FilterControl from './FilterControl';
import ResultCard from './ResultCard';
import {
  StyledCardsContainer,
  StyledDivider,
  StyledFilterContainer,
  StyledHeader,
  StyledLabel,
  StyledName,
  StyledResultsContainer,
} from './ResultsContainer.styles';

type Props = {
  data?: MacnumResponse[];
  activeFilter: FilterType;
  onFilterChange: (filter: FilterType) => void;
}

const ResultsContainer = ({ data, activeFilter, onFilterChange }: Props) => {
  if (!data || data.length === 0) return null;

  const { company_name, allTickets, impactTickets, company_id } = data[0];

  const resolvablePercentage = () => {
    if (allTickets === null || impactTickets === null) return '-';
    return Math.round((Number(impactTickets) / Number(allTickets)) * 100) + '%';
  };

  const edgebootIssues = () => {
    return impactTickets === null ? '-' : Number(impactTickets);
  };

  const totalIssues = () => {
    return allTickets === null ? '-' : Number(allTickets);
  };

  return (
    <StyledResultsContainer>
      <StyledHeader>
        <div>
          <StyledLabel>Company Name</StyledLabel>
          <StyledName>{company_name}</StyledName>
        </div>
        <div>
          <StyledLabel>Macnum</StyledLabel>
          <StyledName>{company_id}</StyledName>
        </div>
      </StyledHeader>

      <StyledDivider />
      <StyledFilterContainer>
        <FilterControl
          activeFilter={activeFilter}
          onFilterChange={onFilterChange}
        />
        <StyledCardsContainer>
          <ResultCard label="Total Issues" value={totalIssues()}/>
          <ResultCard label="Resolvable Issues with edgeboot" value={edgebootIssues()}/>
          <ResultCard label="Resolvable Issue Percentage" value={resolvablePercentage()}/>
        </StyledCardsContainer>

        <CopyResultsButton
          textInfo={{
            companyName: company_name,
            macnum: company_id,
            totalIssues: totalIssues(),
            resolvableIssues: edgebootIssues(),
            filter: activeFilter,
          }}
        />
      </StyledFilterContainer>
    </StyledResultsContainer>
  )
}

export default ResultsContainer
