import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.body};
    color: ${({ theme }) => theme.colors.text.primary};
    line-height: 1.5;

    h1 {
      font-family: ${({ theme }) => theme.fonts.heading};
      font-size: ${({ theme }) => theme.text.size['2xl']};
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary[100]};
      margin: 0;
    }

    p {
      margin: 0;
    }
  }
`;

export default Typography;
