import { CompanyResponse } from "types";
import {
  StyledColumn,
  StyledCompanyRowHeader,
  StyledContainer,
  StyledTitle,
} from "./CompaniesList.styles";
import CompanyCard from "./CompanyCard";

type CompaniesListProps = {
  data: CompanyResponse[];
  term: string;
  onSelect: (company: CompanyResponse) => void;
};

const CompaniesList = ({ data, term, onSelect }: CompaniesListProps) => {
  if (!data || data.length === 0) return null;

  return (
    <StyledContainer>
      <StyledTitle>
        Multiple results for &quot;{term}&quot;, please select a company
      </StyledTitle>
      <StyledCompanyRowHeader>
        <StyledColumn>
          Company Name
        </StyledColumn>
        <StyledColumn>
          Macnum
        </StyledColumn>
      </StyledCompanyRowHeader>
      {data.map((company) => (
        <CompanyCard
          key={company.company_id}
          company={company}
          onSelect={onSelect}
        />
      ))}
    </StyledContainer>
  );
}

export default CompaniesList;
