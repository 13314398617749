import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import { api } from 'api';
import message from './slices/message';

export const reducer = combineReducers({
  [api.reducerPath]: api.reducer,
  message,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
  });

export type RootState = ReturnType<typeof reducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch'];

const store = setupStore();

export default store;
