import { StyledCardContainer, StyledCardValue, StyledLabel } from './ResultsContainer.styles';

type Props = {
  label: string;
  value: string | number;
}

const ResultCard = ({label, value}: Props) => {
  return (
    <StyledCardContainer>
      <StyledLabel>{label}</StyledLabel>
      <StyledCardValue>{value}</StyledCardValue>
    </StyledCardContainer>
  )
}

export default ResultCard;
