import styled from 'styled-components';

export const StyledResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.medium};
  padding: 24px;
  gap: 12px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-top: 32px;
`;

export const StyledLabel = styled.p`
  text-align: left;
  font-size: ${({ theme }) => theme.text.size.md};
`;

export const StyledName = styled.p`
  color: ${({ theme }) => theme.colors.text.white};
  font-size: ${({ theme }) => theme.text.size['4xl']};
  ${({ theme }) => theme.media} {
    font-size: ${({ theme }) => theme.text.size.md};
  }
  font-weight: 600;
`;

export const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.border.hr };
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const StyledCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${({ theme }) => theme.media} {
    flex-direction: column;
  }
  gap: 12px;
  margin-top: 24px;
`;

export const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.dark};
  padding: 24px;
  border-radius: 8px;
  width: 420px;
  height: 130px;

  ${({ theme }) => theme.media} {
    width: 100%;
    padding: 10px 5px;
    height: 100px;
  }
`;

export const StyledCardValue = styled.div`
  font-weight: 600;
  font-size: 96px;
  line-height: 112px;
  color: ${({ theme }) => theme.colors.text.white};
  margin-top: 10px;

  ${({ theme }) => theme.media} {
    font-size: 46px;
    line-height: 54px;
  }
`;
