import styled from 'styled-components';

export const StyledDefaultLayout = styled.div`
  background-color: ${({ theme }) => theme.colors.background.dark};
  color: ${({ theme }) => theme.colors.text.primary};
  min-height: 100vh;
  font-size: ${({ theme }) => theme.text.size.md};
`;

export const StyledContentLayout = styled.div`
  padding: 16px 32px;
  max-width: 1440px;

  ${({ theme }) => theme.media} {
    padding: 16px;
  }
`;
