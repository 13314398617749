import styled from 'styled-components';

export const StyledTitle = styled.p`
  text-align: left;
  color: ${({ theme }) => theme.colors.text.white};
  font-size: ${({ theme }) => theme.text.size.xl};
`;

export const StyledLabel = styled.label`
  text-align: left;
`;

export const StyledSearchInput = styled.input`
  height: 38px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.dark};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.text.primary};
  padding: 0 15px;
`;

export const StyledContainer = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  gap: 15px;
`;

export const StyledSubmitButton = styled.button`
  background: ${({ theme }) => theme.colors.button.primary};
  border-radius: 4px;
  width: 160px;
  height: 38px;
  border-width: 0px;
  color: ${({ theme }) => theme.colors.text.dark};
  font-size: ${({ theme }) => theme.text.size.md};
  font-family: 'Poppins', sans-serif;

  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.button.primaryHover};
  }

  :disabled {
    background: ${({ theme }) => theme.colors.button.disabled};
    color: ${({ theme }) => theme.colors.text.disabled};
    cursor: not-allowed;
  }
`;

export const StyledResultsContainer = styled.div`
  height: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.dark};
`;

export const StyledResultsTitle = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: 700;
  font-size: ${({ theme }) => theme.text.size['2xl']};
  padding: 0px 85px;
  white-space: pre-line;

  ${({ theme }) => theme.media} {
    font-size: ${({ theme }) => theme.text.size.md};
  }
`;

export const StyledSearchImg = styled.img`
  margin-top: 50px;
  background-color: ${({ theme }) => theme.colors.background.dark};

  ${({ theme }) => theme.media} {
    width: 100%;
  }
`;

export const StyledFetchDataLabel = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.text.white};
  font-weight: 700;
  font-size: ${({ theme }) => theme.text.size['2xl']};
`;

export const StyledProgressBar = styled.div`
  margin-top: 30px;
`;

export const StyledCopyButton = styled.button`
  background: ${({ theme }) => theme.colors.background.medium};
  border: 1px solid ${({ theme }) => theme.colors.button.secondary};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.text.white};
  font-size: ${({ theme }) => theme.text.size.sm};
  width: 238px;
  height: 32px;
  font-weight: 600;

  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.button.primaryHover};
    color: ${({ theme }) => theme.colors.text.dark};
    border: 1px solid ${({ theme }) => theme.colors.button.primaryHover};
  }
`;

export const StyledCopyResultsButtonContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.factorOf(8)};

  .tooltip-text {
    width: 180px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    margin-left: 25px;
    opacity: 1;
    transition: opacity 0.3s;
  }

  .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

`;
