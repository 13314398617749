import { StyledBarProgress, StyledProgressContainer } from "./ProgressBar.styles";

type Props = {
  progress: number;
};

const ProgressBar = ({ progress }: Props) => {

  return (
    <StyledProgressContainer>
      <StyledBarProgress $progress={progress} />
    </StyledProgressContainer>
  );
};

export default ProgressBar;
