import {
  StyledFetchDataLabel,
  StyledResultsContainer,
  StyledProgressBar,
  StyledResultsTitle,
} from '../Home.styles';
import ProgressBar from 'components/ProgressBar/ProgressBar';

type Props = {
  display: boolean;
  progress: number;
}

const LoadingCard = ({display = true, progress}: Props) => {
  if (!display) return null;

  return (
    <StyledResultsContainer>
      <StyledFetchDataLabel>
        Fetching data...
      </StyledFetchDataLabel>
      <StyledResultsTitle>
        Hang in there! We&apos;re lassoing the data and bringing it home.
      </StyledResultsTitle>
      <StyledProgressBar>
        <ProgressBar progress={progress} />
      </StyledProgressBar>

    </StyledResultsContainer>
  )
}

export default LoadingCard;
